import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { NavLink, useLocation, useHistory } from "react-router-dom";
// import carAvailableImg from "../../assets/images/youcab/carAvailableMap.PNG";
import useAxiosFile from "../actions/useaxios";
import { LoadToast, isEmpty, updateToast } from "../../lib/common";
import { useSelector } from "react-redux";

import emptyimage from "../../assets/images/noimg.png";
import config from "../actions/config";
import { toast } from "react-toastify";


const Driverfetch = () => {
  let data = useLocation();

  
  const axiosFile = useAxiosFile();
  const history = useHistory();

  const { admindetails } = useSelector((state) => state.Youcabdata);

  const [Driverfetchdata, setDriverfetchdata] = useState({
    action:"edit",
    withinkm:"",
    drivercount:"ALL",
    request:"",

  });
  const [file, setFile] = useState([]);
  const [errors, setError] = useState({});

  const [buttontxt, setbutton] = useState("Start");

  
  const Validation = () => {
    let error = {};
    var data = Object.entries(Driverfetchdata).map((it) => {
      if (isEmpty(it?.[1]))
        return (error = {
          ...error,
          ...{ [it?.[0]]: `${it?.[0]} is required` },
        });
        if (it?.[1]>=10 && it[0]!="drivercount")
        return (error = {
          ...error,
          ...{ [it?.[0]]: `${it?.[0]} value mustbe less than 10` },
        }); 
         });
    if (!isEmpty(error)) {
      setbutton("Try Again");
      setError(error);
      return true;
    } else return false;
  };
  useEffect(()=>
  {
    getfetcdata()
    
  },[]
  )

  const changefn = (e) => {
    setError({})
    var { id, value } = e.target
    
    setDriverfetchdata({
      ...Driverfetchdata,
      ...{ [e.target.id]: e.target.value },
    });
 
  };

const getfetcdata=async()=>{

  var { success, error, data, msg } = await axiosFile.Driverfecth({
    adminid: admindetails?._id,
    action:"driverfetchdata",
    limit:0,
    page:1
  })
 
  if (!isEmpty(success)) {
    
   
    setDriverfetchdata({
      ...Driverfetchdata,
      ...{ ...data[0] },
    });
  } else {
    setbutton("Try Again");
    setError(error);
  }


}

const Addfetcdata=async()=>{
  setbutton("Processing")

  setDriverfetchdata({
    ...Driverfetchdata,
    ...{ ["adminid"]: admindetails?._id, },
  });

  const id = LoadToast();
  var Error=Validation()
  if (Error) return updateToast(id, "fix");
  else{
  var { success, error, data, msg } = await axiosFile.Driverfecth(Driverfetchdata)
  updateToast(id, msg, success);
  setbutton("Start");
  if (!isEmpty(success)) {
    
      setbutton("Done");

    
  } else {
    setbutton("Try Again");
    setError(error);
  }
}

}


  return (
    <div>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
     Driver fetch
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
              
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9 grid-margin stretch-card ">
          <div className="card form-card">
            <div className="pt-5">
              <form className="forms-sample">

                
              <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Within km
                  </label>
                  <Form.Control
                    type="number"
                    id="withinkm"
                    placeholder="Within km"
                    className="new_input"
                    value={Driverfetchdata?.withinkm}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="vehicletype-error" className="error-msg">
                    {errors && errors.withinkm}
                  </span>
                </Form.Group>
                
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                   Driver count
                  </label>
                  <Form.Control
                    type="text"
                    id="drivercount"
                    placeholder="Drivercount"
                    className="new_input"
                    value={Driverfetchdata?.drivercount}
                    onChange={(e) => changefn(e)}
                  />
                  <p className="card-title  mt-3  mb-4" style={{color:'black'}}>To send Request to all Drivers set "ALL" as value</p>
                  <span id="vehicletype-error" className="error-msg">
                    {errors && errors.drivercount}
                  </span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                   Request/driver
                  </label>
                  <Form.Control
                    type="text"
                    id="request"
                    placeholder="Request/driver"
                    className="new_input"
                    value={Driverfetchdata?.request}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="vehicletype-error" className="error-msg">
                    {errors && errors.request}
                  </span>
                </Form.Group>
               

                <div className="mt-5">
                  <button
                    type="button"
                    className=" convert_btn reset_btn mr-4"
                    onClick={() => Addfetcdata()}
                  
                  >
                    Update
                  </button>
                  {/* <NavLink to="/dashboard" className=" convert_btn ">
                    Cancel
                  </NavLink> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Driverfetch;
