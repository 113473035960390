let key = {};
let Back_Url = "";
var FRONT_URL = "";
var ImG = "";
var EnvName = "client";
var DriverAPI = "";
var UserAPI = "";
var RideAPI = "",Socket=""
// var noimg= require("../assets/images/No_image.webp")
// ngrok http -host-header=localhost 8080

if (EnvName === "local") {
  FRONT_URL = "http://localhost:8000";
  Back_Url = "http://localhost:2053/v1";
  Socket = "http://localhost:2053";
  ImG = "http://localhost:2053";
  DriverAPI = "http://localhost:2053/v1/driver";
  UserAPI = "http://localhost:2053/v1/user";
  RideAPI = "http://localhost:2053/v1/rides";
}

if (EnvName === "client") {
  FRONT_URL = "https://youcab-adminpanel.maticz.in";
  Back_Url = "https://api.tranviayoucab.com/v1";
  Socket = "https://api.tranviayoucab.com";
  ImG = "https://api.tranviayoucab.com";
  DriverAPI = "https://api.tranviayoucab.com/v1/driver";
  UserAPI = "https://api.tranviayoucab.com/v1/user";
  RideAPI = "https://api.tranviayoucab.com/v1/rides";
}
if (EnvName === "demo") {
  FRONT_URL = "https://youcab-adminpanel.maticz.in";
  Back_Url = "https://backend-youcab.maticz.in/v1";
  Socket = "https://backend-youcab.maticz.in";
  ImG = "https://backend-youcab.maticz.in";
  DriverAPI = "https://backend-youcab.maticz.in/v1/driver";
  UserAPI = "https://backend-youcab.maticz.in/v1/user";
  RideAPI = "https://backend-youcab.maticz.in/v1/rides";
}

if (EnvName === "test") {
  FRONT_URL = "https://admin-stage.tranviayoucab.com";
  Back_Url = "https://backend-staging.tranviayoucab.com/v1";
  Socket = "https://backend-staging.tranviayoucab.com";
  ImG = "https://backend-staging.tranviayoucab.com";
  DriverAPI = "https://backend-staging.tranviayoucab.com/v1/driver";
  UserAPI = "https://backend-staging.tranviayoucab.com/v1/user";
  RideAPI = "https://backend-staging.tranviayoucab.com/v1/rides";
}

key = {
  Back_Url: `${Back_Url}`,
  DriverAPI: `${DriverAPI}`,
  UserAPI: `${UserAPI}`,
  ImG: ImG,
  RideAPI: `${RideAPI}`,
  Socket:`${Socket}`,
  EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  MOBILE: /^\d{10}$/,
  OTP: /^\d{4}$/,
  NumOnly: /^[1-9]\d*(\.\d+)?$/,
  PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
  OnlyAlbhabets: /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/,
  notSpecil: /^[a-zA-Z0-9]+$/,
  OnlyAlphSpecial: /^[A-Za-z_@.#&+-]*$/,
  KEY: "CabYouRoPject098",
  GOOGLE_API_KEY : "AIzaSyAPKWfh0UfUDsoVxOsBp4X3htsci-yEqeA"

};

export default key;
